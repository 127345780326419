form {
  padding: 20px;
  width: 500px;
  margin: 0 auto;
  text-align: center;

  h1 {
    margin-bottom: 4rem;
  }

  mat-form-field {
    width: 100%;
  }

  .mat-form-field-disabled mat-icon path:last-child {
    fill: #bbb;
  }
  
  .buttons button {
    margin-right: 10px;
  }  
}
