.alert {

  margin: 15px 0;
  border-radius: 10px;
  padding: 15px;

  &.alert-success {
    border: 1px solid green;
    color: green;
    background-color: lighten(green, 70);
  }

  &.alert-error {
    border: 1px solid red;
    color: red;
    background-color: lighten(red, 48);
  }

}
