@import './colors';

table.list {

  width: 100%;
  border-collapse: separate;
  border-spacing: 0 1rem;
  color: #333;

  th {
    font-size: 1.2rem;
    font-weight: normal;
    text-align: left;
    padding: 0 1.5rem;
    color: #555;
    font-weight: bold;
    letter-spacing: 0.1rem;
  }

  td {
    background-color: #fff;
    padding: 1.5rem;

    &.message {
      text-align: center;
      padding: 3rem;
      color: #444;
    }

    &.green {
      border-left: 1rem solid #52e24d;
    }

    &.red {
      border-left: 1rem solid #e04c4c;
    }

    &:first-child {
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem;
    }

    &:last-child {
      border-top-right-radius: 1rem;
      border-bottom-right-radius: 1rem;
    }
  }

  tr.clickable:hover td {
    background-color: $yellow;
    cursor: pointer;
  }

  &.dark {
    th {
      color: #ccc;
    }
  }
}

.table-options {
  button.create {
    background-color: $primary-color;
    padding: 10px 15px;
    color: #fff;
    border: 0;
    border-radius: 20px;

    &:hover {
      background-color: lighten($primary-color, 10);
      cursor: pointer;
    }
  }
}

.table-bottom-options {
  text-align: center;
  padding-top: 10px;

  button.create {
    border: 0;
    background-color: $secondary-color;
    color: #fff;
    padding: 10px 15px;
    border-radius: 20px;

    &:hover {
      cursor: pointer;
      background-color: darken($secondary-color, 10);
    }
  }
}

table.data {
  width: 100%;
  font-size: 1.4rem;
  border-collapse: collapse;
  text-align: left;
  border: 1px solid #ddd;

  &.no-border {
    border: 0;
  }

  th {
    padding: 2px 10px;
    font-size: 1.2rem;
    font-weight: bold;
    background-color: #f6f6f6;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  td {
    padding: 3px 10px;
  }

  td {
    border-bottom: 1px solid #ddd;
  }

  tr.error td {
    color: #a00;
  }

  tr:hover td {
    background-color: #e5f2ff;
    cursor: pointer;
  }
}

table.card {
  th, td {
    padding: 10px;
  }

  th {
    font-size: 1.2rem;
    font-weight: bold;
    color: #666;
    text-align: right;
  }
}
