@import './colors';

html {
  font-size: 62.5%; // 10px
}
body {
  background-color: $background-dark-color;
  font-size: 1.6rem;
  margin: 0;
  font-family: 'Open Sans';
  color: #333;
}

.max-width {
  max-width: 1200px;
  margin: 0 auto;
}

.content-block {
  background-color: #fff;
  color: #333;
  border-radius: 1.5rem;
  margin: 2rem 0;
  padding: 2rem;
}

h1, h2, h3, p {
  margin: 2rem 0;
}

h1 {
  font-family: Roboto;
  font-size: 4rem;
  line-height: 5rem;
}

h2 {
  font-family: Roboto;
  font-size: 3rem;
}

h3 {
  font-family: Roboto;
  font-size: 2rem;
}

p {
  line-height: 3.5rem;
}

a {
  color: inherit;

  &.link {
    color: darken($secondary-color, 10);
    text-decoration: none;
  }

  &:hover {
    color: $primary-color;
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}
